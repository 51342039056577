import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { AccountService } from 'weavix-shared/services/account.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.scss'],
})
export class PageTemplateComponent implements OnInit {
    constructor(private accountService: AccountService,
        private router: Router,
        private route: ActivatedRoute) {
    }

    async ngOnInit() {
        try {
            await this.accountService.connect();
            const url = ['a', this.accountService.getAccountRoute(), ...this.route.snapshot.url.map(x => x.path)];
            this.router.navigate(url, { queryParams: this.route.snapshot.queryParams });
        } catch (e) {
            console.error('navigating to authorize because connect error', e);
            this.router.navigate(['login']);
        }
    }
}
