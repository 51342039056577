import { bootstrap } from './bootstrap';
bootstrap();

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from 'environments/environment';
import { AppModule } from './app/app.module';
import { NewRelicService } from 'weavix-shared/services/new-relic.service';

if (environment.production) {
  enableProdMode();
}

NewRelicService.initializeNewRelic();

const consoleError = console.error;

console.error = (...args) => {
    const exc = args.find(x => x instanceof Error);
    if (exc) NewRelicService.sendError(exc);
    consoleError.apply(console, args);
};

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/custom-service-worker.js');
    }
}).catch(err => console.error(err));
